import React from "react";
import { Typography, Grid, makeStyles, Button, Box } from "@material-ui/core";
import PritLogo from "../../../assets/prit-logo.png";
import { useNavigate } from "react-router-dom";
import {
  CalendarToday as CalendarTodayIcon,
  Payment as PaymentIcon,
  Settings as SettingsIcon,
  Help as HelpIcon,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(6, 4),
  },
  headerBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.10)",
    padding: theme.spacing(2, 5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 2),
    },
  },
  pritLogoImg: {
    cursor: "pointer",
    maxWidth: "100%",
    height: "auto",
    "&:hover": {
      opacity: 0.7,
      transition: "opacity 0.3s ease-in-out",
    },
  },
  title: {
    fontSize: 45,
    textAlign: "center",
    color: "#201D4D",
    fontFamily: "Nunito",
    fontWeight: 700,
    marginBottom: theme.spacing(14),
    marginTop: theme.spacing(7),
    [theme.breakpoints.down("md")]: {
      fontSize: 35,
      marginBottom: theme.spacing(6),
    },
  },
  gridContainer: {
    width: "100%",
    maxWidth: "900px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    flexWrap: "nowrap",
    gap: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      gap: "40px",
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(57, 173, 196, 0.82)",
    borderRadius: "10px",
    width: "220px",
    height: "165px",
    margin: theme.spacing(0, 5),
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "rgba(57, 173, 196, 1)",
    },
  },
  icon: {
    fontSize: "45px !important",
    color: "#FFFFFF",
  },
  label: {
    fontSize: "16px",
    color: "#6c757d",
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
}));

const FaqSection = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const menuItems = [
    {
      label: "Agenda",
      icon: <CalendarTodayIcon className={classes.icon} />,
      section: "Agenda",
      questions: [
        {
          question: "Como meu cliente agenda comigo?",
          answer:
          "No Prit o seu cliente consegue agendar com você apenas acessando o seu link de agendamentos, deste modo não há necessidade de responder cliente, conferir disponibilidade e nem perder horas trocando mensagens para agendar. O agendamento online facilitará seu dia a dia. O cliente não precisará ter nenhum app baixado, basta ele acessar o link de agendamentos, selecionar o serviço e o horário que deseja agendar e confirmar o agendamento! Bem simples, prático e te ajudará muito a otimizar o seu negócio!",
          steps: [
            {
              text: "1º Passo: Compartilhe o link de agendamento com o cliente.",
              image: require("../../../assets/images/calendar/calendar1.png"),
            },
            {
              text: "2º Passo: O cliente acessa o link e escolhe o serviço desejado.",
              image: require("../../../assets/images/calendar/calendar2.png"),
            },
            {
              text: "3º Passo: O cliente irá selecionar o dia que ele deseja agendar de acordo com a disponibilidade configurada pelo profissional.",
              image: require("../../../assets/images/calendar/calendar3.png"),
            },
            {
              text: "4º Passo:  O cliente irá selecionar o profissional que realizará o serviço e o horário que ele deseja agendar de acordo com a disponibilidade configurada pelo profissional.",
              image: require("../../../assets/images/calendar/calendar4.png"),
            },
            {
              text: "5º Passo: O cliente irá conferir as informações do agendamento, caso esteja tudo correto, ele irá clicar em 'Confirmar Agendamento'.",
              image: require("../../../assets/images/calendar/calendar5.png"),
            },
            {
              text: "6º Passo: Agendamento realizado com sucesso!",
              image: require("../../../assets/images/calendar/calendar6.png"),
            },
          ],
        },
        {
          question: "Como divulgar meu link nas redes sociais?",
          answer: "Maximize seus agendamentos! Disponibilize seu link nas redes sociais e veja seus clientes agendando facilmente com o Prit!",
          steps: [
            {
              text: "1º Passo: Abra o Prit e vá em Perfil.",
              image: require("../../../assets/images/calendar/link/redes1.png"),
            },
            {
              text: "2º Passo: Clique em Meu link > E em seguida: Copiar link.",
              image: require("../../../assets/images/calendar/link/redes2.png"),
            },
            {
              text: "3º Passo: Coloque o link na sua mensagem automática do seu WhatsApp Business (No WhatsApp Business clicar em Ferramentas comerciais.",
              image: require("../../../assets/images/calendar/link/redes3.png"),
            },
            {
              text: "4º Passo:  Selecione Mensagem de Ausência > Escreva a sua mensagem e colar o seu link > Salvar) Selecione Mensagem de Saudação > Escreva a sua mensagem e colar o seu link > Salvar).",
              image: require("../../../assets/images/calendar/link/redes4.png"),
            },
            {
              text: "5º Passo: Coloque o link na sua bio do seu Instagram. Abra seu Instagram > Vá em Perfil > Clique em Editar perfil.",
              image: require("../../../assets/images/calendar/link/redes5.png"),
            },
            {
              text: "6º Passo: Em seguida, clique em Adicionar Links.",
              image: require("../../../assets/images/calendar/link/redes6.png"),
            },
            {
              text: "7º Passo: Agora basta ir em Adicionar Link Externo.",
              image: require("../../../assets/images/calendar/link/redes7.png"),
            },
            {
              text: "8º Passo:  Colar o link do app na URL e adicionar uma frase da sua preferência, como 'Agende aqui' e > CONCLUIR. Pronto, seu link já estará disponível em seu Perfil.",
              image: require("../../../assets/images/calendar/link/redes8.png"),
            },
          ],
        },
        {
          question: "Como adicionar uma nova reserva manualmente?",
          answer:
          "Além de receber agendamentos online diretamente de seus clientes com o Prit, você também pode inserir novas reservas dentro do app para seus clientes com poucos passos. Veja como é fácil este processo para agendar seu cliente com poucos cliques dentro do nosso app.",
          steps: [
            {
              text: "1º Passo: Abra o app Prit e na aba agenda e clique no botão '+'.",
              image: require("../../../assets/images/calendar/reserve/reserve1.png"),
            },
            {
              text: "2º Passo: Selecione o cliente que irá realizar o serviço",
              image: require("../../../assets/images/calendar/reserve/reserve2.png"),
            },
            {
              text: "3º Passo: Selecione a data e hora da reserva.",
              image: require("../../../assets/images/calendar/reserve/reserve3.png"),
            },
            {
              text: "4º Passo: Selecione o profissional que irá realizar o serviço.",
              image: require("../../../assets/images/calendar/reserve/reserve4.png"),
            },
            {
              text: "5º Passo: Selecione o serviço e clique em 'Adicionar Serviço'.",
              image: require("../../../assets/images/calendar/reserve/reserve5.png"),
            },
            {
              text: "6º Passo: Clique em 'Salvar'.",
              image: require("../../../assets/images/calendar/reserve/reserve6.png"),
            },
            {
              text: "7º Passo: Você pode enviar uma notificação de reserva para o seu cliente via WhatsApp. Prontinho, reserva salva e seu cliente será notificado 24h antes do horário agendado, de acordo com o seu plano.",
              image: require("../../../assets/images/calendar/reserve/reserve7.png"),
            },
          ],
        },
        {
          question: "Como bloquear um horário?",
          answer:
          "Aqui no Prit, nosso propósito é fazer com que profissionais tenham autonomia e organização em seus horários! Por isso na agenda você pode bloquear horários específicos sem precisar alterar sua disponibilidade de horários cadastrada. Deste modo clientes não conseguirão agendar naquele horário que você já possui um compromisso particular.",
          steps: [
            {
              text: "1º Passo: Abra o app Prit e na aba agenda, clique no ícone '+' para bloqueio de horário.",
              image: require("../../../assets/images/calendar/block/block1.png"),
            },
            {
              text: "2º Passo: Para bloquear um horário, você deve selecionar o dia e o horário de início do bloqueio, o dia e o horário do fim do bloqueio, o profissional que terá seu horário bloqueado. E por fim clique em bloquear horário para confirmar a ação.",
              image: require("../../../assets/images/calendar/block/block2.png"),
            },
            {
              text: "3º Passo e opcional: Você pode selecionar mais de um dia para bloqueio caso precise bloquear vários dias. É só selecionar Inicio da Data e Termino da data de fim de férias.",
              image: require("../../../assets/images/calendar/block/block2.png"),
            },
          ],
        },
      ],
    },
    {
      label: "Cobrança Antecipada",
      icon: <PaymentIcon className={classes.icon} />,
      section: "Cobrança Antecipada",
      questions: [
        {
          question: "Como cobrar o valor da reserva após o serviço?",
          answer: "Para cobrar seus clientes após o agendamento utilizando o Prit Pay, siga estes passos:",
          steps: [
            {
              text: "1º Passo: Acesse “Perfil” no aplicativo e clique em Prit Pay.",
              image: require("../../../assets/images/payment/pay.png"),
            },
            {
              text: "2º Passo: Clique	 em 'Prit Pay'.",
              image: require("../../../assets/images/payment/pay0.png"),
            },
            {
              text: "3º Passo: Clique	 em 'Termos Prit Pay'.",
              image: require("../../../assets/images/payment/pay1.png"),
            },
            {
              text: "4º Passo: Aceite os termos e escolha os métodos de pagamento que deseja habilitar (Pix, Cartão ou ambos).",
              image: require("../../../assets/images/payment/pay2.png"),
            },
            {
              text: "5º Passo: Volte para a página “Prit Pay” e clique em “Dados bancários”. Preencha corretamente as informações bancárias solicitadas.",
              image: require("../../../assets/images/payment/pay3.png"),
            },
            {
              text: "6º Passo: Com essa configuração inicial concluída, você estará pronto para realizar cobranças após o serviço.",
              image: require("../../../assets/images/payment/pay4.png"),
            },
          ],
        },
        {
          question: "Cobrança automática ou manual pelo checkout",
          answer: "Essa é a forma mais prática de cobrar seus clientes, com envio automático de link de pagamento via WhatsApp, ou se preferir, realize a cobrança manualmente.",
          steps: [
            {
              text: "1º Passo: Acesse então “Cobrança Posterior” dentro da página de Prit Pay.",
              image: require("../../../assets/images/payment/pay5.png"),
            },
            {
              text: "2º Passo:Ative a opção 'Ativar envio de lembrete via WhatsApp com link de pagamento para o cliente'.",
              image: require("../../../assets/images/payment/pay6.png"),
            },
            {
              text: "3º Passo: Salve as configurações.",
              image: require("../../../assets/images/payment/pay7.png"),
            },
            {
              text: "4º Passo: Você também pode ativar a opção “Ativar envio de notificação com link de pagamento para o cliente” para que ele receba notificação no aplicativo. A partir disso, durante a realização do serviço, o cliente receberá automaticamente um link de pagamento via WhatsApp ou via Notificação, onde ele poderá escolher entre Pix ou Cartão, com base nos métodos habilitados por você.",
              image: require("../../../assets/images/payment/pay8.png"),
            },
            {
              text: "Cobrança Manual pelo Checkout. 1º passo: Após concluir a configuração inicial (aceitar termos e cadastrar conta), acesse a opção “Vendas” no aplicativo.",
              image: require("../../../assets/images/payment/pay9.png"),
            },
            {
              text: "2º Passo: Em ”Para ser cobrado”  localize a reserva correspondente ao serviço.",
              image: require("../../../assets/images/payment/pay10.png"),
            },
            {
              text: "3º Passo: Selecione em “como o cliente vai pagar” a opção: Pagamento Online.",
              image: require("../../../assets/images/payment/pay11.png"),
            },
            {
              text: "4º Passo: Aqui seu cliente pode pagar via QR Code com você ou então você enviar o link para ele via WhatsApp.",
              image: require("../../../assets/images/payment/pay12.png"),
            },
            {
              text: "5º Passo: Assim que o cliente realizar o pagamento, a sua reserva irá para Histórico de Vendas e o sistema atualizará automaticamente os dados financeiros, garantindo que você tenha total controle pelo aplicativo. ",
              image: require("../../../assets/images/payment/pay13.png"),
            },
          ],
        },
        {
          question: "Como funciona o PritPay? (pagamento online)",
          answer: "Com o Prit Pay você garante que seus clientes só agendem após pagar um valor que você mesmo irá estipular, sendo completamente manipulável para a melhor comodidade para você e seu cliente. Atualmente, há 3 tipos de cobrança antecipada disponíveis para ativação no Prit Pay, sendo elas: uma tarifa mínima (valor fixo que não será alterado pelo preço do serviço), uma porcentagem (valor que varia com o preço do serviço), poderá ainda cobrar de forma não obrigatória, o valor total do serviço (100%), oferecendo um desconto para aqueles que optarem por realizar o pagamento adiantado. Em todos os casos você poderá adicionar um prazo para que seu cliente cancele e tenha o dinheiro de volta. Com esta modalidade, você se destaca no mercado, evita seus prejuízos com cancelamentos em cima da hora ou ainda com clientes que agendam mas não comparecem. Com o Prit Pay você deixa de ser refém dos seus clientes e se torna protagonista do seu negócio!",
        },
        {
          question: "Taxas e prazos do PritPay.",
          answer: "O PritPay é a ferramenta de cobrança dentro do próprio aplicativo Prit para que você, dono do business, consiga cobrar de seus clientes o pagamento dos serviços realizados. Fica ao seu critério escolher as formas de pagamento e de cobrança, além de também definir políticas de reagendamento e de reembolso dos valores. A plataforma de pagamentos online segue algumas regras de taxas e prazos de recebimento que você precisa saber, são elas: Taxas: para pagamentos feitos via pix: a taxa sobre essas transações é de 1%, para pagamentos feitos via cartão de crédito: a taxa sobre essas transações é de 3%, não há taxa de transferência desses valores do app para sua conta corrente. Prazos de recebimento: valores pagos pelos clientes através de pix ficam disponíveis para saque a partir do momento em que o cliente não tem mais direito a reembolso e isso é definido por você na sua política de reembolso em 'Cobrança Antecipada'. OBS.: Valores pagos através do cartão de crédito ficam disponíveis para saque após 30 dias. Quanto ao saque, os valores disponíveis no PritPay podem ser sacados diariamente, contudo, por se tratar de uma transferência TED, será concluído em horário bancário - dias úteis. Se o saque for solicitado até às 15h de um dia útil, o valor cairá na sua conta no mesmo dia. Após este horário, cairá no próximo dia útil. Por exemplo: Um saque realizado em uma quinta-feira às 10h da manhã, cai na sua conta no mesmo dia. Após as 15h, cairá na sexta-feira. Já um saque realizado em uma sexta-feira após as 15h cairá apenas na segunda-feira (próximo dia útil)..",
          steps: [
            {
              text: "1º Passo: Abra o aplicativo e clique em 'Perfil' no canto inferior direito da tela.",
              image: require("../../../assets/images/payment/taxas/taxas1.png"),
            },
            {
              text: "2º Passo: Clique em: 'PritPay'.",
              image: require("../../../assets/images/payment/taxas/taxas2.png"),
            },
            {
              text: "3º Passo: Clique em 'Termos Prit'.",
              image: require("../../../assets/images/payment/taxas/taxas3.png"),
            },
            {
              text: "4º Passo: Selecione o método de pagamento para seus clientes efetuarem o pagamento e confiram as taxas e prazos de cada um.",
              image: require("../../../assets/images/payment/taxas/taxas4.png"),
            },
          ],
        },
        {
          question: "Como ativar o Pagamento antecipado no Prit?",
          answer: "Ativar a cobrança antecipada no Prit representa um avanço na gestão financeira e na experiência dos nossos usuários. Essa funcionalidade proporciona aos nossos profissionais uma maneira eficaz de garantir o compromisso dos clientes, reduzindo os cancelamentos e otimizando alguns processos operacionais. Sem dor de cabeça e sem prejuízo com cancelamentos em cima da hora!",
          steps: [
            {
              text: "1º Passo: Abra o Prit e vá em 'Perfil'",
              image: require("../../../assets/images/payment/paymentAdvance/advance1.png"),
            },
            {
              text: "2º Passo: Clique em 'PritPay'.",
              image: require("../../../assets/images/payment/paymentAdvance/advance2.png"),
            },
            {
              text: "3º Passo: Clique em 'Termos PritPay.",
              image: require("../../../assets/images/payment/paymentAdvance/advance3.png"),
            },
            {
              text: "4º Passo: Clique em 'Aceitar termos' e configure as formas de pagamento.",
              image: require("../../../assets/images/payment/paymentAdvance/advance4.png"),
            },
            {
              text: "5º Passo: Retorne para página anterior e clique em 'Dados Bancários'.",
              image: require("../../../assets/images/payment/paymentAdvance/advance5.png"),
            },
            {
              text: "6º Passo: Para fazer as configurações necessárias : insira o nome do seu banco, número da agência, número da conta, tipo de conta, CPF OU CNPJ, nome do titular da conta, clique em 'Salvar Dados Bancários'.",
              image: require("../../../assets/images/payment/paymentAdvance/advance6.png"),
            },
            {
              text: "7º Passo: Retorne para a página anterior e clique em 'Cobrança Antecipada' para ajustar da melhor forma pra você!",
              image: require("../../../assets/images/payment/paymentAdvance/advance7.png"),
            },
            {
              text: "8º Passo:  Escolha uma porcentagem para cobrar de forma antecipada.",
              image: require("../../../assets/images/payment/paymentAdvance/advance8.png"),
            },
            {
              text: "9º Passo: Configure um tempo mínimo para que os clientes cancelem com direito ao reembolso.",
              image: require("../../../assets/images/payment/paymentAdvance/advance9.png"),
            },
            {
              text: "10º Passo: Você pode optar por cobrar uma tarifa mínima ou 100% do valor de forma antecipada, caso não faça sentido pra você cobrar uma porcentagem!",
              image: require("../../../assets/images/payment/paymentAdvance/advance10.png"),
            },
            {
              text: "11º Passo: Por último, configure os dias e horários que a sua cobrança antecipada será aplicada e clique em 'Salvar'",
              image: require("../../../assets/images/payment/paymentAdvance/advance11.png"),
            },
          ],
        },
        {
          question: "Como fazer o saque de valores recebidos no Prit?",
          answer: "Com o Prit, receber agendamentos com pagamento nunca foi tão fácil! Assim que começar a receber seus primeiros pagamentos via app, você já poderá solicitar um saque de forma simples e rápida!",
          steps: [
            {
              text: "1º Passo: Abra o Prit e vá em 'Perfil'.",
              image: require("../../../assets/images/payment/paymentReceive/payReceive1.png"),
            },
            {
              text: "2º Passo: Clique em 'PritPay'.",
              image: require("../../../assets/images/payment/paymentReceive/payReceive2.png"),
            },
            {
              text: "3º Passo: Salve as configurações.",
              image: require("../../../assets/images/payment/paymentReceive/payReceive3.png"),
            },
            {
              text: "4º Passo: Você também pode ativar a opção 'Ativar envio de notificação com link de pagamento para o cliente' para que ele receba notificação no aplicativo. A partir disso, durante a realização do serviço, o cliente receberá automaticamente um link de pagamento via WhatsApp ou via Notificação, onde ele poderá escolher entre Pix ou Cartão, com base nos métodos habilitados por você.",
              image: require("../../../assets/images/payment/paymentReceive/payReceive4.png"),
            },
          ],
        },
      ],
    },
    {
      label: "Configurações",
      icon: <SettingsIcon className={classes.icon} />,
      section: "Configurações",
      questions: [
        {
          question: "Como alterar preço e duração do meu serviço?",
          answer: "No aplicativo Prit, você consegue configurar todas as informações do seu estabelecimento: horário de funcionamento, serviços realizados, valores, duração, profissionais, formas de pagamento, etc. Uma das configurações mais importantes são as configurações de valor e duração dos serviços que o seu estabelecimento realiza para que seus clientes consigam agendar corretamente através do link com acesso a todas as informações relacionadas aos serviços e para que o app consiga manter o controle da sua agenda e do financeiro do seu business.",
          steps: [
            {
              text: "1º Passo: Abra o aplicativo e clique em “Perfil” no canto inferior direito da tela.",
              image: require("../../../assets/images/settingsImg/settings1.png"),
            },
            {
              text: "2º Passo: Clique em 'Dados da empresa'.",
              image: require("../../../assets/images/settingsImg/settings2.png"),
            },
            {
              text: "3º Passo: Clique em 'Serviços'.",
              image: require("../../../assets/images/settingsImg/settings3.png"),
            },
            {
              text: "4º Passo: Selecione a categoria do serviço que você gostaria de configurar/alterar o valor e duração.",
              image: require("../../../assets/images/settingsImg/settings4.png"),
            },
            {
              text: "5º Passo: Clique ao lado do nome do serviço onde mostra o tempo e preço.",
              image: require("../../../assets/images/settingsImg/settings5.png"),
            },
            {
              text: "6º Passo: Defina o valor e duração daquele serviço e clique em 'Salvar'.",
              image: require("../../../assets/images/settingsImg/settings6.png"),
            },
          ],
        },
        {
          question: "Como adicionar novos profissionais?",
          answer: "Aqui no Prit você consegue cadastrar novos profissionais em sua agenda sempre que precisar, delimitando níveis de permissão entre 'funcionário' e 'gerência'. Com essa ação a agenda se tornará mais organizada, pois cada profissional pode ter sua configuração específica dentre os serviços realizados e sua disponibilidade de horários. Logo, não ocorrerá conflitos entre os horários de cada profissional de sua equipe.",
          steps: [
            {
              text: "1º Passo: Abra o Prit e vá na aba Perfil no canto interior direito.",
              image: require("../../../assets/images/settingsImg/addProf/add1.png"),
            },
            {
              text: "2º Passo: Clique em 'Dados da empresa'.",
              image: require("../../../assets/images/settingsImg/addProf/add2.png"),
            },
            {
              text: "3º Passo: Clique na opção 'profissionais'.",
              image: require("../../../assets/images/settingsImg/addProf/add3.png"),
            },
            {
              text: "4º Passo:  Em profissionais, será encontrado os profissionais já adicionados. Para adição de um novo profissional basta clicar no ícone '+'.",
              image: require("../../../assets/images/settingsImg/addProf/add4.png"),
            },
            {
              text: "5º Passo: Ao adicionar o profissional, 3 informações devem ser preenchidas obrigatoriamente. O nome do profissional, o seu número de telefone.",
              image: require("../../../assets/images/settingsImg/addProf/add5.png"),
            },
            {
              text: "6º Passo: Adicione o seu grau de permissão e após o preenchimento, a ação deve ser salva clicando no ícone 'Salvar' em azul no canto inferior da tela, e o profissional deve baixar o app em seu aparelho celular, realizando o cadastro com seu número que foi adicionado.",
              image: require("../../../assets/images/settingsImg/addProf/add6.png"),
            },
          ],
        },
        {
          question: "Como adicionar horário de almoço?",
          answer: "Para evitar que um cliente agende com você durante seu horário de almoço, é importante evitar que esse horário esteja indisponível para agendamento. Para isso, é necessário adicionar um intervalo de horário. E é super simples, veja só!",
          steps: [
            {
              text: "1º Passo: Abra o Prit e vá na aba Perfil no canto interior direito.",
              image: require("../../../assets/images/settingsImg/lunch/lunch1.png"),
            },
            {
              text: "2º Passo: Clique em 'Dados da empresa'.",
              image: require("../../../assets/images/settingsImg/lunch/lunch2.png"),
            },
            {
              text: "3º Passo: Clique na opção 'Horário de funcionamento'.",
              image: require("../../../assets/images/settingsImg/lunch/lunch3.png"),
            },
            {
              text: "4º Passo: Clique em 'Editar'.",
              image: require("../../../assets/images/settingsImg/lunch/lunch4.png"),
            },
            {
              text: "5º Passo: Crie um intervalo para o primeiro período e, posteriormente, em aplicar.",
              image: require("../../../assets/images/settingsImg/lunch/lunch5.png"),
            },
            {
              text: "6º Passo: Clique em adicionar novo intervalo de horário.",
              image: require("../../../assets/images/settingsImg/lunch/lunch6.png"),
            },
            {
              text: "7º Passo: Crie um intervalo para o segundo período de atendimento.",
              image: require("../../../assets/images/settingsImg/lunch/lunch7.png"),
            },
            {
              text: "8º Passo:  Com os períodos criados, clique em 'Salvar'.",
              image: require("../../../assets/images/settingsImg/lunch/lunch8.png"),
            },
            {
              text: "9º Passo: Clique em salvar novamente.",
              image: require("../../../assets/images/settingsImg/lunch/lunch9.png"),
            },
          ],
        },
        {
          question: "Como alterar o horário de funcionamento?",
          answer: "A opção de alterar o horário de funcionamento do seu estabelecimento no Prit é uma funcionalidade essencial para proporcionar flexibilidade e adaptabilidade aos nossos usuários, permitindo que você, profissional, ajuste seu horário de acordo com a sua necessidade!",
          steps: [
            {
              text: "1º Passo: Abra o Prit e vá na aba Perfil no canto interior direito.",
              image: require("../../../assets/images/settingsImg/hours/hours1.png"),
            },
            {
              text: "2º Passo: Clique em 'Dados da empresa'.",
              image: require("../../../assets/images/settingsImg/hours/hours2.png"),
            },
            {
              text: "3º Passo: Clique na opção 'profissionais'.",
              image: require("../../../assets/images/settingsImg/hours/hours3.png"),
            },
            {
              text: "4º Passo:  Em profissionais, será encontrado os profissionais já adicionados. Para adição de um novo profissional basta clicar no ícone '+'.",
              image: require("../../../assets/images/settingsImg/hours/hours4.png"),
            },
            {
              text: "5º Passo: Ao adicionar o profissional, 3 informações devem ser preenchidas obrigatoriamente. O nome do profissional, o seu número de telefone.",
              image: require("../../../assets/images/settingsImg/hours/hours5.png"),
            },
            {
              text: "6º Passo: Adicione o seu grau de permissão e após o preenchimento, a ação deve ser salva clicando no ícone 'Salvar' em azul no canto inferior da tela, e o profissional deve baixar o app em seu aparelho celular, realizando o cadastro com seu número que foi adicionado.",
              image: require("../../../assets/images/settingsImg/hours/hours6.png"),
            },
            {
              text: "7º Passo: Adicione o seu grau de permissão e após o preenchimento, a ação deve ser salva clicando no ícone 'Salvar' em azul no canto inferior da tela, e o profissional deve baixar o app em seu aparelho celular, realizando o cadastro com seu número que foi adicionado.",
              image: require("../../../assets/images/settingsImg/hours/hours7.png"),
            },
            {
              text: "8º Passo: Adicione o seu grau de permissão e após o preenchimento, a ação deve ser salva clicando no ícone 'Salvar' em azul no canto inferior da tela, e o profissional deve baixar o app em seu aparelho celular, realizando o cadastro com seu número que foi adicionado.",
              image: require("../../../assets/images/settingsImg/hours/hours8.png"),
            },
            {
              text: "9º Passo: Adicione o seu grau de permissão e após o preenchimento, a ação deve ser salva clicando no ícone 'Salvar' em azul no canto inferior da tela, e o profissional deve baixar o app em seu aparelho celular, realizando o cadastro com seu número que foi adicionado.",
              image: require("../../../assets/images/settingsImg/hours/hours9.png"),
            },
            {
              text: "10º Passo: Adicione o seu grau de permissão e após o preenchimento, a ação deve ser salva clicando no ícone 'Salvar' em azul no canto inferior da tela, e o profissional deve baixar o app em seu aparelho celular, realizando o cadastro com seu número que foi adicionado.",
              image: require("../../../assets/images/settingsImg/hours/hours10.png"),
            },
            {
              text: "11º Passo: Adicione o seu grau de permissão e após o preenchimento, a ação deve ser salva clicando no ícone 'Salvar' em azul no canto inferior da tela, e o profissional deve baixar o app em seu aparelho celular, realizando o cadastro com seu número que foi adicionado.",
              image: require("../../../assets/images/settingsImg/hours/hours11.png"),
            },
          ],
        },
      ],
    },
    {
      label: "Perguntas Frequentes",
      icon: <HelpIcon className={classes.icon} />,
      section: "Perguntas Frequentes",
      questions: [
        {
          question: "Por que os meses à frente não estão disponíveis na hora de agendar?",
          answer: "Para que o seu cliente consiga agendar online com você pelo seu perfil no Prit, é importante que suas configurações na aba de horário de funcionamento estejam corretas. Com o Prit, existe a possibilidade de estender as semanas e meses à frente para agendamento online, então caso você precise que mais datas sejam abertas, você pode configurar diretamente a disponibilidade da agenda.",
          steps: [
            {
              text: "1º Passo: Abra o app Prit e vá na aba de 'Perfil', no canto inferior direito.",
              image: require("../../../assets/images/help/help1.png"),
            },
            {
              text: "2º Passo: Ao entrar na aba de perfil, clique em 'Dados da empresa'.",
              image: require("../../../assets/images/help/help2.png"),
            },
            {
              text: "3º Passo: Clique em 'Horário de funcionamento'.",
              image: require("../../../assets/images/help/help3.png"),
            },
            {
              text: "4º Passo: Você deve ir em 'período de disponibilidade' e alterar para o que for mais compatível com a sua empresa e salvar no final da página.",
              image: require("../../../assets/images/help/help4.png"),
            },
          ],
        },
        {
          question: "Como fazer o checkout da reserva?",
          answer: "Após realizado o atendimento do cliente, é muito importante fazer o checkout para que todos os dados apareçam no controle financeiro do seu estabelecimento! Antes de fazer o checkout você precisa ativar as configurações de pagamento (Perfil > Configurações > Configurações de pagamento).",
          steps: [
            {
              text: "1º Passo: Abra o aplicativo e clique na aba vendas, e clique no serviço a fazer checkout.",
              image: require("../../../assets/images/help/checkout/checkout1.png"),
            },
            {
              text: "2º Passo: Selecione a forma de pagamento do serviço.",
              image: require("../../../assets/images/help/checkout/checkout2.png"),
            },
            {
              text: "3º Passo: Selecione a melhor opção para você.",
              image: require("../../../assets/images/help/checkout/checkout3.png"),
            },
            {
              text: "4º Passo: Clique em 'Cobrar saldo'.",
              image: require("../../../assets/images/help/checkout/checkout4.png"),
            },
            {
              text: "5º Passo: Após cobrar o saldo, o serviço irá entrar no seu histórico de vendas e contará no seu controle financeiro.",
              image: require("../../../assets/images/help/checkout/checkout5.png"),
            },
          ],
        },
        {
          question: "Por que fiz o saque no app, mas o valor não caiu na minha conta?",
          answer: "No Prit você tem a opção de receber os pagamentos dos seus clientes dentro do próprio app, seja por pagamento antecipado ou após a realização do serviço com o checkout, e você pode sacar esses valores recebidos a qualquer momento. Valores pagos pelos clientes através de Pix ficam disponíveis para saque a partir do momento em que o cliente não tem mais direito a reembolso e isso é definido por você na sua política de reembolso dentro de “Cobrança Antecipada” e valores pagos através do cartão de crédito ficam disponíveis para saque após 30 dias. Mas o que acontece quando o valor já está disponível, você clica em 'Sacar saldo' e mesmo assim o valor não cai na sua conta bancária? Essa demora ou falha na transferência de valores de dentro do app Prit para a conta do seu banco pode acontecer por duas razões: Dia útil ou informações bancárias incorretas.",
          steps: [
            {
              text: "1º Passo: Abra o aplicativo e clique em 'Perfil' no canto inferior direito da tela.",
              image: require("../../../assets/images/help/payError/payError1.png"),
            },
            {
              text: "2º Passo: Clique em 'Prit Pay'.",
              image: require("../../../assets/images/help/payError/payError2.png"),
            },
            {
              text: "3º Passo: Clique em Dados Bancários.",
              image: require("../../../assets/images/help/payError/payError3.png"),
            },
            {
              text: "4º Passo: Coloque as suas informações de número de conta, agência, CPF e nome completo exatamente de acordo como estão essas informações na conta do seu banco. Confira se todos os números estão corretos, altere se algum estiver errado e clique em 'Salvar'.",
              image: require("../../../assets/images/help/payError/payError4.png"),
            },
          ],
        },
        {
          question: "Minha cliente agendou com pagamento, mas não recebi o dinheiro na minha conta, o que houve?",
          answer: "O Prit proporciona à você, profissional, praticidade e otimização dos processos, uma parte disso é a nossa ferramenta de cobrança pelo app. No entanto, mesmo com a facilidade proporcionada, podem surgir dúvidas relacionadas ao processamento dos valores cobrados. Vamos entender melhor o que pode ter acontecido, caso o cliente tenha realizado o agendamento com pagamento e os valores não tenham caído na sua conta? O app não faz a transferência de forma automática para sua conta assim que há o agendamento, portanto, para receber estes valores, você precisa fazer o saque dentro da nossa plataforma! Lembrando que: para que você receba os valores, é necessário que estejam disponíveis para saque, então sempre levar em consideração o prazo de reembolso que você estipulou para seu cliente.",
          steps: [
            {
              text: "1º Passo: Entrar no app e clicar em 'Perfil'.",
              image: require("../../../assets/images/help/receive/receive1.png"),
            },
            {
              text: "2º Passo: Clique em 'Prit Pay'.",
              image: require("../../../assets/images/help/receive/receive2.png"),
            },
            {
              text: "3º Passo: Clique na aba 'Sacar Saldo'",
              image: require("../../../assets/images/help/receive/receive3.png"),
            },
            {
              text: "4º Passo: Efetue o saque no botão indicado (botão verde 'Sacar Saldo') e aguarde a transferência para sua conta!",
              image: require("../../../assets/images/help/receive/receive4.png"),
            },
          ],
        },
        {
          question: "Sou manicure e não encontro minha lista de serviços.",
          answer: "Como manicure, você pode se encontrar diante de várias opções ao selecionar a categoria que melhor descreve seu trabalho, aqui no app Prit a categoria que se encaixa com os serviços oferecidos pela Manicure ou Nail Designer, como conhecemos, é a categoria denominada 'Unhas'. A categoria 'Unhas' é direta e específica, o que torna mais fácil para os clientes entenderem imediatamente que tipo de serviços você oferece. Seja manicure, pedicure, aplicação de unhas de gel, arte nas unhas ou qualquer outra coisa relacionada às unhas, esta categoria engloba todos esses serviços de forma clara e concisa.",
        },
      ],
    },
  ];

  const handleClick = (sectionData) => {
    navigate("/faq/questions", {
      state: { title: sectionData.section, questions: sectionData.questions },
    });
  };

  return (
    <div>
      <Box className={classes.headerBox}>
        <img
          src={PritLogo}
          alt="prit logo"
          className={classes.pritLogoImg}
          onClick={() => navigate("/")}
        />
      </Box>
      <div className={classes.container}>
        <Typography variant="h4" className={classes.title}>
          Como podemos ajudar você?
        </Typography>
        <Grid container className={classes.gridContainer}>
          {menuItems.map((item, index) => (
            <Grid item key={index}>
              <div>
                <Button
                  className={classes.button}
                  onClick={() => handleClick(item)}
                >
                  {item.icon}
                </Button>
                <Typography className={classes.label}>{item.label}</Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default FaqSection;
