import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Box, makeStyles, Button } from "@material-ui/core";
import PritLogo from "../../../../assets/prit-logo.png";

const useStyles = makeStyles((theme) => ({
  headerBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.10)",
    padding: theme.spacing(2, 5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 2),
    },
  },
  pritLogoImg: {
    cursor: "pointer",
    maxWidth: "100%",
    height: "auto",
    "&:hover": {
      opacity: 0.7,
      transition: "opacity 0.3s ease-in-out",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(6, 4),
    maxWidth: "900px",
    margin: "0 auto",
  },
  title: {
    fontSize: 30,
    color: "#201D4D",
    fontFamily: "Nunito",
    fontWeight: 700,
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
  description: {
    fontSize: 17,
    color: "#333",
    fontFamily: "Nunito",
    marginBottom: theme.spacing(2),
    textAlign: "justify",
  },
  stepContainer: {
    marginBottom: theme.spacing(4),
    textAlign: "left",
    width: "100%",
  },
  stepText: {
    marginTop: "20px",
    fontSize: 15,
    fontWeight: 500,
    color: "#555",
    marginBottom: theme.spacing(1),
    fontFamily: "Nunito",
  },
  image: {
    width: "100%",
    maxWidth: "200px",
    borderRadius: "8px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    marginBottom: theme.spacing(2),
  },
}));

const AnswerDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const { question } = location.state || {};

  if (!question) {
    return (
      <Box className={classes.container}>
        <Typography variant="h4">Nenhuma resposta encontrada</Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate("/faq/questions")}
        >
          Voltar para perguntas
        </Button>
      </Box>
    );
  }

  return (
    <div>
      <Box className={classes.headerBox}>
        <img
          src={PritLogo}
          alt="Prit Logo"
          className={classes.pritLogoImg}
          onClick={() => navigate("/faq")}
        />
      </Box>
      <Box className={classes.container}>
        <Typography variant="h4" className={classes.title}>
          {question.question}
        </Typography>
        <Typography className={classes.description}>
          {question.answer}
        </Typography>
        {question.steps &&
          question.steps.map((step, index) => (
            <Box key={index} className={classes.stepContainer}>
              <Typography className={classes.stepText}>{step.text}</Typography>
              {step.image && (
                <img
                  src={step.image}
                  alt={`Imagem do passo ${index + 1}`}
                  className={classes.image}
                />
              )}
            </Box>
          ))}
      </Box>
    </div>
  );
};

export default AnswerDetails;
