import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import ReactPixel from "react-facebook-pixel";
import Home from "./pages/Home";
import "./App.css";
import FaqSection from "./pages/Home/faqSection";
import ProfessionalsExplorer from "./pages/ProfessionalsExplorer";
import ProfessionalPage from "./pages/ProfessionalPage";
import AnswerDetails from "./pages/Home/faqSection/answerDetails";
import AnswerSection from "./pages/Home/faqSection/answerSection";
import { appendGTMHeadScript } from "./utils/GTM";

function App() {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
  ReactPixel.pageView();
  appendGTMHeadScript();

  return (
    <>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KW7TL5F"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/features" element={<Home />} />
          <Route path="/cases" element={<Home />} />
          <Route path="/pricing" element={<Home />} />     
          <Route path="/profissionais" element={<ProfessionalsExplorer />} />
          <Route path="/profissionais/buscar" element={<ProfessionalPage />} />
          <Route path="/faq" element={<FaqSection />} />
          <Route path="/faq/questions" element={<AnswerSection />} />
         <Route path="/faq/answer" element={<AnswerDetails />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
